import { useEffect, useLayoutEffect, useRef, useState } from "react";
import LottieAnimation from "react-lottie";
import animation from "./data.json";
import "../../scss/global.scss";
import "./home.scss";

const Home: React.FC = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animation,
        renderSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    // Auto Scrolling function
    const [scrollPercentage, setScrollPercentage] = useState<number>(0);
    const smoothScrollTo = (
        targetPercentage: number,
        duration: number
    ): void => {
        const targetY: number = (targetPercentage * window.innerHeight) / 100;
        const startY: number = window.pageYOffset;
        const distance: number = targetY - startY;
        const startTime: number = performance.now();
        const easeInOutQuad = (t: number): number =>
            t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        const animateScroll = (currentTime: number): void => {
            const elapsedTime: number = currentTime - startTime;
            const timeFraction: number = Math.min(1, elapsedTime / duration);
            window.scrollTo(
                0,
                Math.ceil(easeInOutQuad(timeFraction) * distance + startY)
            );
            if (elapsedTime < duration) {
                requestAnimationFrame(animateScroll);
            }
        };
        requestAnimationFrame(animateScroll);
    };
    let scrollDisabled = false;

    useEffect(() => {
        const handleScroll = () => {
            scrollDisabled = true;
            console.log("scrolling");
            window.removeEventListener("scroll", handleScroll);
        };

        setTimeout(() => {
            if (!scrollDisabled) {
                if (window.innerWidth > 1023) {
                    smoothScrollTo(100, 1000);
                }
            }
        }, 9000);

        window.addEventListener("scroll", handleScroll);
    }, []);

    return (
        <>
            <div className='introvid'>
                <video autoPlay muted>
				    <source src="../video/intro.mp4" type="video/mp4" />
			</video>
            </div>
            <div className='animation-wrapper'>
                <LottieAnimation
                    options={defaultOptions}
                    height={"auto"}
                    width={"100%"}
                    style={{
                        overflow: "hidden",
                        position: "relative",
                        zIndex: 1,
                    }}
                />
            </div>
            <div className='container'>
                <div className='footer contain'>
                    <div className='top-half'>
                        <div className='copy'>
                            <div className='pink-rec'></div>
                            <div>
                                <h1>
                                    22,000 SQ FT <br></br> WAREHOUSE STYLE{" "}
                                    <br></br>
                                    OFFICE SPACE
                                </h1>
                            </div>
                        </div>
                        <div className='loc'>
                            <div className='loc-copy'>
                                <div className='pink-rec'></div>
                                <h2>
                                    101 <br></br> BAYHAM STREET <br></br> CAMDEN
                                </h2>
                            </div>
                            <div className='loc-copy'>
                                <div className='pink-rec'></div>
                                <h2>
                                    AVAILABLE <br></br> Q1 2025
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className='bottom-half'>
                        <div className='conc'>
                            <div className='pink-rec'></div>
                            <h2>contact</h2>
                        </div>
                        <div className='agents-wrapper'>
                            <div className='agents'>
                                <img src='../images/ecp.svg' alt='ecp' />
                                <div className='agent'>
                                    <p className='pink'>ANDREW OKIN</p>
                                    <p>
                                        <a href='mailto:AOKIN@EDWARDCHARLES.CO.UK'>
                                            AOKIN@EDWARDCHARLES.CO.UK
                                        </a>
                                    </p>
                                    <p>
                                        <a href='tel:+44 (0)7887 714491'>
                                            +44 (0)7887 714491
                                        </a>
                                    </p>
                                </div>
                                <div className='agent'>
                                    <p className='pink'>Ian Bradshaw</p>
                                    <p>
                                        <a href='mailto:IBradshaw@edwardcharles.co.uk'>
                                            IBradshaw@edwardcharles.co.uk
                                        </a>
                                    </p>
                                    <p>
                                        <a href='tel:+44 (0)7468 525486'>
                                            +44 (0)7468 525486
                                        </a>
                                    </p>
                                </div>
                                <div className='agent'>
                                    <p className='pink'> Ollie Mitchell </p>
                                    <p>
                                        <a href='mailto:omitchell@edwardcharles.co.uk'>
                                            omitchell@edwardcharles.co.uk
                                        </a>
                                    </p>
                                    <p>
                                        <a href='tel:+44 (0)7769 310 206'>
                                            +44 (0)7769 310 206
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className='agents'>
                                <img src='../images/jll.svg' alt='ecp' />
                                <div className='agent'>
                                    <p className='pink'>George Reynolds</p>
                                    <p>
                                        <a href='mailto:George.reynolds@jll.com'>
                                            George.reynolds@jll.com
                                        </a>
                                    </p>
                                    <p>
                                        <a href='tel:+44 (0)7592 112 110'>
                                            +44 (0)7592 112 110
                                        </a>
                                    </p>
                                </div>
                                <div className='agent'>
                                    <p className='pink'> Michael OLIVER</p>
                                    <p>
                                        <a href='mailto:Michael.Oliver@jll.com'>
                                            Michael.Oliver@jll.com
                                        </a>
                                    </p>
                                    <p>
                                        <a href='tel:+44 (0)7591 200 793'>
                                            +44 (0)7591 200 793
                                        </a>
                                    </p>
                                </div>
                                <div className='agent'>
                                    <p className='pink'> sarah Brisbane </p>
                                    <p>
                                        <a href='mailto:Sarah.Brisbane@jll.com'>
                                            Sarah.Brisbane@jll.com
                                        </a>
                                    </p>
                                    <p>
                                        <a href='tel:+44 (0)7769 201 458'>
                                            +44 (0)7769 201 458
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
