import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import Burger from "./burger";
import "./narbar.scss";

const Nav: React.FC = () => {

    return (
        <>
            
                    <Burger />

        </>
    );
};
export default Nav;
